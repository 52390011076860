<template>
  <v-row no-gutters>
    <v-col md="3">
      <text-field
        v-model="search"
        outlined
        label="Search Project"
        placeholder="Type to search"
      />
    </v-col>
    <v-col md="12">
      <data-table
        :search="search"
        :items="members"
        :headers="headers"
        :custom-columns="['item.member', 'item.modifyTime', 'item.deleteTime']"
      >
        <template v-slot:custom-render-item.member="{ item }">
          <div class="d-flex align-center">
            <div class="custom-member">
              <img
                class="custom-member-img"
                :src="item.member.img"
                alt=""
              >
            </div>
            {{ item.member.name }}
          </div>
        </template>
        <template v-slot:custom-render-item.modifyTime="{ item }">
          <v-switch
            v-model="item.modifyTime"
            color="success"
            hide-details
          />
        </template>
        <template v-slot:custom-render-item.deleteTime="{ item }">
          <v-switch
            v-model="item.deleteTime"
            color="success"
            hide-details
          />
        </template>
      </data-table>
    </v-col>
  </v-row>
</template>

<script>
import TextField from '@/components/ui/TextField/TextField';
import DataTable from '@/components/collections/DataTable/DataTable';
import { MEMBER_RIGHTS } from '@/views/Settings/constants';

export default {
  name: 'MemberRights',
  components: { DataTable, TextField },
  data() {
    return {
      search: null,
      headers: MEMBER_RIGHTS,
      members: [
        {
          id: '1',
          member: {
            // eslint-disable-next-line global-require
            img: require('@/static/account.png'),
            name: 'test',
          },
          activity: '',
          modifyTime: true,
          deleteTime: false,
        },
        {
          id: 'w',
          member: {
            // eslint-disable-next-line global-require
            img: require('@/static/account.png'),
            name: 'test',
          },
          activity: '',
          modifyTime: false,
          deleteTime: false,
        },
        {
          id: '3',
          member: {
            // eslint-disable-next-line global-require
            img: require('@/static/account.png'),
            name: 'test',
          },
          activity: '',
          modifyTime: true,
          deleteTime: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .custom-member {
    display: flex;
    align-items: center;
    &-img {
      width: 28px;
      height: 28px;
      border: 2px solid $white;
      border-radius: 11px;
      margin-right: 8px;
    }
  }
  .v-input--switch {
    margin-top: 0 !important;
    &.v-input--is-label-active {
      ::v-deep.v-input--switch__thumb {
        left: 0 !important;
      }
      ::v-deep.v-input--selection-controls__ripple {
        left: -11px !important;
      }
    }
    ::v-deep.v-input--switch__thumb {
      height: 14px !important;
      width: 14px !important;
      top: calc(50% - 5px);
      background-color: $white;
      left: 5px;
    }
    ::v-deep.v-input--switch__track {
      height: 20px !important;
      width: 34px !important;
    }
  }
  ::v-deep .v-input--selection-controls__input .v-input--selection-controls__ripple {
    left: -5px !important;
    top: calc(50% - 15px) !important;
  }
</style>
